import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import NewAccount from "./newAccount"
import { Role } from "../../interfaces/role"

export default function SignupTeacher() {
  return (
    <Layout>
      <SEO title="Teacher Sign Up" />

      <NewAccount isStudent={false} role={Role.Teacher} isIndividual={false} />
    </Layout>
  )
}
